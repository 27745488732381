import React from 'react'
import './About.css'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-scroll'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import Skills from '../skills/Skills'

const About = () => {
  return (
    <Section title="About" titulo="¿Quíen soy?">
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">
            <h2>Sobre mí</h2>
            <p>
              Soy José Jorge{' '}
              <span role="img" aria-label="lightning">
                👾
              </span>{' '}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ✅
              </span>{' '}
              Soy un ingeniero de DevOps con experiencia en la automatización y
              racionalización de los procesos de desarrollo de software.
            </p>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ✅
                </span>{' '}
                Especializado en:
              </p>
              <Typewriter
                options={{
                  strings: [
                    'Renovación y automatización',
                    'Migración de tecnologías a cloud',
                    'Desarrollo de software',
                    'Metodologías ágiles',
                    'Aseguramiento de la calidad',
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              <p>.</p>
            </div>
            <p>
              <span role="img" aria-label="lightning">
                ✅
              </span>{' '}
              Tengo una fuerte experiencia en la administración de Linux/Unix,
              Windows Server, scripting y soluciones serverless.
            </p>

            <p>
              Como ingeniero de DevOps, soy responsable de garantizar el
              funcionamiento fluido y eficiente del proceso de desarrollo de
              software. Trabajando en{' '}
              <Link
                className="textLink"
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
              >
                distintos lugares
              </Link>
              , resolviendo distintos problemas.
            </p>
            <div className="location-wrapper">
              <svg
                className="octicon octicon-location"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
                ></path>
              </svg>
              <p>Guatemala, Guatemala</p>
            </div>
          </div>
        </Fade>
        <Skills />
      </div>
    </Section>
  )
}

export default About
