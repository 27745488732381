import React, { useState } from 'react'
import './ContactForm.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  email: {
    '& > *': {
      marginBottom: theme.spacing(2),
      backgroundColor: '#3b4353',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.Mui-focused': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.MuiFilledInput-underline:before': {
        borderBottom: '2px solid #6f7b9b',
      },
      '&.MuiFilledInput-underline:after': {
        borderBottom: '2px solid #258b9e',
      },
    },
  },
  message: {
    '& > *': {
      marginBottom: theme.spacing(2),
      backgroundColor: '#3b4353',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.Mui-focused': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.MuiFilledInput-underline:before': {
        borderBottom: '2px solid #6f7b9b',
      },
      '&.MuiFilledInput-underline:after': {
        borderBottom: '2px solid #258b9e',
      },
    },
  },
  submit: {
    '&': {
      backgroundColor: '#07B0F2',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#2C87BF',
        boxShadow: 'none',
      },
    },
    '& > *': {
      color: 'white',
      fontSize: '15px',
      fontWeight: '600',
    },
  },
}))

const ContactForm = () => {
  const [status, setStatus] = useState('')
  const [emailText, setEmailText] = useState('')
  const [messageText, setMessageText] = useState('')

  const classes = useStyles()

  const submitForm = (ev) => {
    ev.preventDefault()

    var requestJSoN = {
      service_id: 'MailPersonal',
      template_id: 'template_puv5w0a',
      user_id: '9Sh_I6rJXN-Vkg-f6',
      template_params: {
        from_name: emailText,
        message: messageText,
      },
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestJSoN),
    }

    fetch('https://api.emailjs.com/api/v1.0/email/send', requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setStatus('SUCCESS')
          setEmailText('')
          setMessageText('')
        } else {
          setStatus('ERROR')
        }
      })
      .then((data) => {
        console.log(data)
      })
      .catch(() => {
        setStatus('ERROR')
      })
  }

  const handleEmailChange = (event) => {
    const input = String(event.target.value)
    setEmailText(input)
  }

  const handleMessageChange = (event) => {
    const input = String(event.target.value)
    setMessageText(input)
  }

  return (
    <div className="contact-form-wrapper">
      <form
        className="contact-form"
        onSubmit={submitForm}
        action="https://formspree.io/mvolplar"
        method="POST"
      >
        <TextField
          className={classes.email}
          type="email"
          name="email"
          label="Email"
          value={emailText}
          onChange={handleEmailChange}
          variant="filled"
        />
        <TextField
          className={classes.message}
          type="text"
          name="message"
          label="Mensaje"
          value={messageText}
          onChange={handleMessageChange}
          multiline
          rows="5"
          variant="filled"
        />
        {status === 'SUCCESS' ? (
          <p className="email-success">¡Nos pondremos en contacto pronto!</p>
        ) : (
          <Button className={classes.submit} type="submit" variant="contained">
            Enviar
          </Button>
        )}
        {status === 'ERROR' && (
          <p>Ooops! Ocurrió un error, intenta de nuevo más tarde</p>
        )}
      </form>
    </div>
  )
}

export default ContactForm
